import React from 'react'
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from '../components/rows/hero'
import Intro from '../components/rows/intro'
import ImgSection from '../components/rows/imgsection'
import Outro from '../components/rows/outro'

import Banner from '../images/img/hero/shipping-hero.jpg'

import Slide1 from '../images/img/shipping-gallery/shipping-1.jpg'
import Slide2 from '../images/img/shipping-gallery/shipping-2.jpg'
import Slide3 from '../images/img/shipping-gallery/shipping-3.jpg'
import Slide4 from '../images/img/shipping-gallery/shipping-4.jpg'
import Slide5 from '../images/img/shipping-gallery/shipping-5.jpg'
import Slide6 from '../images/img/shipping-gallery/shipping-6.jpg'
import Slide7 from '../images/img/shipping-gallery/shipping-7.jpg'
import Slide8 from '../images/img/shipping-gallery/shipping-8.jpg'

const slideShow = [
  {
    image: Slide1,
  },
  {
    image: Slide2,
  },
  {
    image: Slide3,
  },
  {
    image: Slide4,
  },
  {
    image: Slide5,
  },
  {
    image: Slide6,
  },
  {
    image: Slide7,
  },
  {
    image: Slide8,
  }
]

export default function Shipping({data}) { // <== Make sure data is sent to the function

    return (
        <Layout>
        <Seo title="Shipping & Installation" />
        <Hero 
            title="Shipping & Installation"
            banner={Banner}
        />
        {data.allStrapiShipping.edges.map(({ node }) => ( // <== Map out the data from the node.
            <> 
                <Intro key={node.shipping_intro.id}
                    title={node.shipping_intro.title}
                    subtitle={node.shipping_intro.subtitle}
                    description={node.shipping_intro.description}
                />
                <ImgSection key={node.shipping_img.id}
                    buttonOrange
                    subtitle={node.shipping_img.subtitle}
                    title={node.shipping_img.title}
                    description={node.shipping_img.description}      
                    slideshow={slideShow}
                    link={node.shipping_img.button_title}
                    href={node.shipping_img.button_link}
                />
                <Outro key={node.shipping_outro.id}
                    subtitle={node.shipping_outro.subtitle}
                    title={node.shipping_outro.title}
                    link={node.shipping_outro.button_title}
                    href={node.shipping_outro.button_link}
                />
            </>
        ))}            
        </Layout>    
    )
}


export const query = graphql`
query Shipping {
    allStrapiShipping {
      edges {
        node {
          shipping_intro {
            id
            subtitle
            title
            description
          }
          shipping_img {
            id
            subtitle
            title
            description
          }
          shipping_outro {
            id
            subtitle
            title
            button_title
            button_link
          }
        }
      }
    }
  }
`